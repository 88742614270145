exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-work-jsx": () => import("./../../../src/pages/our-work.jsx" /* webpackChunkName: "component---src-pages-our-work-jsx" */),
  "component---src-pages-partner-with-us-jsx": () => import("./../../../src/pages/partner-with-us.jsx" /* webpackChunkName: "component---src-pages-partner-with-us-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-insights-insight-category-jsx": () => import("./../../../src/templates/insights/{insightCategory}.jsx" /* webpackChunkName: "component---src-templates-insights-insight-category-jsx" */),
  "component---src-templates-insights-insight-slug-jsx": () => import("./../../../src/templates/insights/{insightSlug}.jsx" /* webpackChunkName: "component---src-templates-insights-insight-slug-jsx" */),
  "component---src-templates-insights-jsx": () => import("./../../../src/templates/insights.jsx" /* webpackChunkName: "component---src-templates-insights-jsx" */)
}

